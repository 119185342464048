'use strict';

Gri.module({
  name: 'input-gamma',
  ieVersion: null,
  $el: $('.input-gamma'),
  container: '.input-gamma',
  fn: function () {
    this.$el.find('input').keyup(function () {
      var rex = new RegExp($(this).val(), 'i');
      $($(this).data('target')).hide();
      $($(this).data('target')).filter(function () {
        return rex.test($(this).text());
      }).show();
    });
  }
});
